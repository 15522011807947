import { Route, Routes, useLocation } from "react-router-dom";
import Landing from "./Landing";
import { useState, useEffect } from "react";
import PageNotFoundView from "./PageNotFoundView";

function App() {
  const [deals, setDeals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const apiUrl =
      "https://us-central1-red-flag-or-deal-breaker.cloudfunctions.net/rfdb/pp";
    fetch(apiUrl, { headers: { "Access-Control-Allow-Origin": "*" } })
      .then((response) => response.json())
      .then((data) => {
        setDeals(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Landing isLoading={isLoading} deals={deals} />}
      />
      ;<Route path="*" element={<PageNotFoundView />}></Route>
    </Routes>
  );
}

export default App;
