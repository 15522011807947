import { useState } from "react";
import "./Landing.css";
import HeaderView from "./HeaderView/HeaderView";
import DealsGridView from "./DealsGridView/DealsGridView";
import PrimaryLogoView from "./PrimaryLogoView/PrimaryLogoView";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { auto } from "@popperjs/core";
import { borderRadius } from "@mui/system";

function Landing({ isLoading, deals }) {
  const [open, setOpen] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const handleOpen = (deal) => {
    setSelectedDeal(deal);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: auto,
    bgcolor: "white",
    border: "2px solid #FFFFFF",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="scrollable-content">
      <HeaderView />
      <PrimaryLogoView />
      <DealsGridView
        isLoading={isLoading}
        deals={deals}
        onClickItem={(deal) => handleOpen(deal)}
      />
      <div>
        <Modal open={open} onClose={handleClose}>
          {selectedDeal ? (
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%", // or whatever height you need
                width: auto,
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                borderRadius: 2,
                boxShadow: 24,
                p: 4,
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${selectedDeal.brand_image_url})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  opacity: 0.05, // Adjust the opacity value as needed
                  zIndex: -1, // Ensure it stays behind other content
                  pointerEvents: "none", // Ensure it doesn't interfere with other elements
                  filter: "grayscale(100%)",
                },
              }}>
              <div>
                <Typography
                  id="modal-modal-title"
                  sx={{
                    fontFamily: "Georgia",
                    fontSize: 24,
                    fontWeight: 600,
                    color: "black",
                  }}>
                  {selectedDeal.player_name} x {selectedDeal.brand_name}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2, fontFamily: "Georgia", color: "black" }}>
                  {selectedDeal.description}
                </Typography>
              </div>
            </Box>
          ) : (
            <div></div>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default Landing;
