import "./DealsGridView.css";
import DealGridItemView from "./DealGridItemView";
import LoadingDealGridView from "./LoadingDealGridView";

function DealsGridView({ isLoading, deals, onClickItem }) {
  return (
    <div className="deals-container">
      <h1>Previous Deals</h1>
      {isLoading ? (
        <LoadingDealGridView />
      ) : (
        <div class="deals-grid-container" id="player-deals">
          {deals.map((deal, index) => (
            <DealGridItemView
              key={index}
              item={deal}
              onClick={() => onClickItem(deal)}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default DealsGridView;
